import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import bodyImage from "../assets/body-image.jpg";
import "./Form.css";

export default function Form() {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      form.current.name.value &&
      form.current.user_email.value &&
      form.current.message.value
    ) {
      emailjs
        .sendForm(
          "service_e55hb55",
          "template_q20zb6u",
          form.current,
          "K8pIDn0VhUiNHIhpO"
        )
        .then((result) => {
          console.log(result.text);
          form.current.reset();
          setSubmitted(true);
        })
        .catch((error) => {
          console.log(error.text);
        });
    } else {
      alert("Please fill out all fields before submitting.");
    }
  };

  const formStyle = {
    backgroundImage: `url(${bodyImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
  };

  return (
    <div className="form-container" style={formStyle}>
      {submitted ? (
        <div className="message-overlay">
          <p className="text-info-succ">
            Form has been submitted successfully!
          </p>
        </div>
      ) : (
        <div className="form-wrapper">
          <div className="message-overlay">
            <p className="text-info">
              Fill out the form below to set up a time for your first session!
            </p>
          </div>
          <form className="form" ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form>
        </div>
      )}
    </div>
  );
}
