import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../assets/live-courageous-logo.png";

export default function Header() {
  return (
    <header className="header">
      <nav className="navbar" id="navbar" role="navigation">
        <div className="navbar-logo">
          <Link to="/" className="navbar__header__logolink">
            <img
              src={logo}
              alt="Live Courageous"
            />
          </Link>
        </div>
      </nav>
    </header>
  );
}
