import React from "react";
import { Link } from "react-router-dom";
import "./Body.css";
import bodyImage from "../assets/body-image.jpg";
import menDevelopment from "../assets/mens-development.jpg";
import newsLetter from "../assets/news-letter.jpg";
import consulting from "../assets/business-consulting.jpg";

export default function Body() {
  const handleMenDevelopmentClick = () => {
    window.location.href = "https://livecourageous.teachable.com";
  };

  const handleNewsletterClick = () => {
    window.location.href = "https://ko-fi.com/charlesshivers";
  };

  return (
    <main className="main-body">
      <img className="image-body" src={bodyImage} alt="Live Courageous" />
      <div className="circle-container">
        <div className="circle-wrapper">
          <div className="circle">
            <img
              src={menDevelopment}
              className="circle-image"
              alt="Mens Development"
            />
          </div>
          <div className="circle-text-container">
            <h1 className="circle-text">Men's Development</h1>
            <p className="circle-description">
              Discover your potential, transform into your ideal self.
            </p>
            <div className="banner__button-wrapper">
              <button className="button" onClick={handleMenDevelopmentClick}>
                Start the Journey
              </button>
            </div>
          </div>
        </div>
        <div className="circle-wrapper">
          <div className="circle">
            <img src={newsLetter} className="circle-image" alt="Newsletter" />
          </div>
          <div className="circle-text-container">
            <h1 className="circle-text">Newsletter</h1>
            <p className="circle-description">
              Experience healing together; join my chronic disease journey now.
            </p>
            <button className="button" onClick={handleNewsletterClick}>
              Sign up
            </button>
          </div>
        </div>
        <div className="circle-wrapper">
          <div className="circle">
            <img
              src={consulting}
              className="circle-image"
              alt="Business Consulting"
            />
          </div>
          <div className="circle-text-container">
            <h1 className="circle-text">Business Consulting</h1>
            <p className="circle-description">
              Identify and overcome the areas holding you back from greater
              success.
            </p>
            <Link to="/biz">
              <button className="button">Free Discovery Session</button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
